@font-face {
font-family: '__yahooBeta_ad5d79';
src: url(/_yc/_next/static/media/149fb4b75624a9ea-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 200 900;
}@font-face {font-family: '__yahooBeta_Fallback_ad5d79';src: local("Arial");ascent-override: 102.08%;descent-override: 18.72%;line-gap-override: 0.00%;size-adjust: 99.34%
}.__className_ad5d79 {font-family: '__yahooBeta_ad5d79', '__yahooBeta_Fallback_ad5d79'
}.__variable_ad5d79 {--font-yahoo-beta: '__yahooBeta_ad5d79', '__yahooBeta_Fallback_ad5d79'
}

